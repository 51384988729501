import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "./seo"
import Layout from "../components/layout"

export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        published_date
        slug
        description
      }
    }
    allContentJson {
      edges {
        node {
          post {
            back
            nav {
              previous
              blog
              next
            }
          }
        }
      }
    }
  }
`

const BlogPostTemplate = ({ data, ...props }) => {

  const post = data.markdownRemark
  const content = data.allContentJson.edges[0].node
  const { previous, next } = props.pageContext

  return (
    <Layout {...props}>

      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        slug={post.frontmatter.slug}
        // image={post.frontmatter.illustration}
        defer={false}
      />

      <div className ="c-markdown">
        <div className ="u-pd-hz-m u-pd-hz-0@main">

          <div className ="l-grid">
            <div className ="l-col l-col-one-half">
              <Link to="/blog" rel="next">
                {content.post.back}
              </Link>
            </div>
            <div className ="l-col l-col-one-half">
              <p className ="c-p u-right">{post.frontmatter.published_date}</p>
            </div>
          </div>

        <section dangerouslySetInnerHTML={{ __html: post.html }} />

          <div className ="l-grid u-pd-vt-xl">
            <div className ="l-col l-col-one-third">
              {previous && (
              <Link to={previous.frontmatter.slug} rel="prev">
                &lt;&lt; {content.post.nav.previous}
              </Link>
            )}
            </div>
            <div className ="l-col l-col-one-third u-center">
              <Link to="/blog" rel="next">
              {content.post.nav.blog}
              </Link>
            </div>
            <div className ="l-col l-col-one-third u-right">
              {next && (
                <Link to={next.frontmatter.slug} rel="next">
                  {content.post.nav.next} &gt;&gt;
                </Link>
              )}
            </div>
          </div>

        </div>
      </div>

    </Layout>

  )

}

export default BlogPostTemplate